<template>
  <!-- begin::post-card-list -->
  <ul class="post-card-list" :class="{'pb-3': !hasMore}">
    <li class="post-card-item" v-for="(item, idx) in items" :key="idx">
      <div class="post-card">
        <div class="post-content">
          <div class="post-category">
            <div class="badge">
              <span class="badge-text">{{ item.ctgr }}</span>
            </div>
          </div>
<!--          <p class="post-title">-->
<!--            <router-link :to="item.viewLink" class="title-link" data-anim="true">-->
<!--              <span class="title">{{ item.tit }}</span>-->
<!--            </router-link>-->
<!--          </p>-->
          <div class="post-subtext">
            <p v-if="isTag" class="text">{{ item.tags }}</p>
          </div>
        </div>
        <div class="post-image">
          <router-link :to="item.viewLink" class="image-link" data-anim="true">
            <img :src="item.rprsImgPtUrl" alt="" />
          </router-link>
        </div>

        <div v-if="isBadge" class="post-badge">
          <i v-if="item.badge" :class="`icon-${item.badge}`"></i>
<!--          <i class="icon-unselection"></i>-->
<!--          <i class="icon-selection"></i>-->
<!--          <i class="icon-proceeding"></i>-->
        </div>
      </div>
    </li>
  </ul>
  <!-- end::post-card-list -->
</template>

<script>
export default {
  name: 'CommonCard',
  props: {
    hasMore: {
      type: Boolean,
      default: false
    },
    items: Array,
    isTag: Boolean,
    isBadge: Boolean
  },
};
</script>
