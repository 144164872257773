<template>
  <!-- begin::page-container(#소상공인정보놀이터) -->
  <main id="sme-noticeinfo" class="page-container">
    <div class="page-header">
      <div class="page-component">
        <div class="page-header-title">
          <h2 class="title">상세페이지 제작해보기</h2>
        </div>

        <div class="tag-search mt-5">
          <div>
            <div class="form-group" @keyup.enter="$router.push({name:'Playground3', query:{tag: plygrndTag}})">
              <input v-model="plygrndTag" type="text" class="form-control" placeholder="태그검색">
              <input type="submit" class="screen-out">
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="page-body" data-fill="true">
      <nav class="page-nav">
        <ul class="nav-list page-component">
          <li class="nav-item" :class="{'is-selected': (plygrndCtgrDcd3 === '')}">
            <router-link :to="{name:'Playground3'}"><span class="nav-text">전체</span></router-link>
          </li>
          <li
              v-for="(item, idx) in ctgrItems"
              class="nav-item"
              :key="idx"
              :class="{'is-selected' : item.dcd === plygrndCtgrDcd3}"
          >
            <router-link :to="{name:'Playground3', query:{ctgrCode:item.dcd}}" class="nav-link"><span class="nav-text">{{ item.dcdVal }}</span></router-link>
          </li>
        </ul>
      </nav>

      <common-list :div-class="['page-component']"
                   :is-loading="isLoading"
                   :is-no-result="isNoResult">
        <div class="post-list-wrapper notice-list-wrapper">
          <div class="list-header">
            <template v-if="isMobile">
              <div class="filter-selector">
                <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
                  <option value="">최신순</option>
                  <option value="I">인기순</option>
                </select>
              </div>
            </template>
            <template v-else>
              <div class="header-column">
                <h3 class="title">전체 <span class="text-muted">{{ totalCount }}</span></h3>
              </div>
              <div class="header-column">
                <div class="filter-selector">
                  <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
                    <option value="">최신순</option>
                    <option value="I">인기순</option>
                  </select>
                </div>
              </div>
            </template>
          </div>
          <common-card :items="refineItems" :is-tag="true" />

          <div v-if="hasMore" class="page-buttons" data-align="center">
            <button class="btn btn-lg btn-outline-white" @click="morePage">
              <span class="text">더보기</span>
            </button>
          </div>

        </div>
      </common-list>
    </div>
  </main>
  <!-- end::page-container(#소상공인정보놀이터) -->
</template>

<script>
import {ACT_GET_COMMON_CODE_LIST, ACT_GET_PLAYGROUND3_LIST} from '../../../store/_act_consts';
import {getItems, lengthCheck, queryToValue, setPaging, viewTags} from '../../../assets/js/utils';
import CommonCard from '../../../components/common/CommonCard';
import {mapGetters} from 'vuex';
import NoResult from '../../../components/common/NoResult';
import CommonList from '../../../components/common/CommonList';

export default {
  name: 'Playground3',
  components: {
    CommonCard,
    NoResult,
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    sortCd: '',
    plygrndTag: '',
    plygrndCtgrDcd3: '',
    items: [],
    ctgrItems: [],

  }),
  watch:{
    $route(to){
      this.plygrndCtgrDcd3 = queryToValue(to.query.ctgrCode, false, '');
      this.plygrndTag = queryToValue(to.query.tag, false);
      this.changeCtgr(this.plygrndCtgrDcd3);
    }
  },
  computed:{
    ...mapGetters('common', ['isMobile']),
    refineItems(){
      return this.items.map(x => ({
        ctgr: x.plygrndCtgr3,
        tit: x.plygrndTit,
        tags: viewTags(x, 'plygrndTag'),
        rprsImgPtUrl: x.rprsImgPtUrl,
        viewLink: (this.$route.query.ctgrCode ? `/playground3/${x.plygrndId}/view?ctgrCode=${this.$route.query.ctgrCode}`: `/playground3/${x.plygrndId}/view`)
      }));
    },
    isNoResult(){
      return this.items.length === 0;
    }
  },
  created() {
    this.plygrndCtgrDcd3 = queryToValue(this.$route.query.ctgrCode, false, '');
    this.plygrndTag = queryToValue(this.$route.query.tag, false);

    this.getPlayGroundCtgrList()
    this.getPlaygroundList(true);

  },
  methods: {
    getPlayGroundCtgrList(){
      this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {dcd: '1013', masterYn: 'N'}).then(res=>{
        if(lengthCheck(res)) {
          this.ctgrItems = getItems(res);
        }
      }).catch(e => {
        console.error(e);
      });
    },
    getPlaygroundList(isInit) {
      if(isInit) {
        this.items = [];
        this.isLoading = true;
        this.pageNo = 1;
      }
      this.$store.dispatch(`community/${ACT_GET_PLAYGROUND3_LIST}`, {
        plygrndTag: this.plygrndTag,
        plygrndCtgrDcd3: this.plygrndCtgrDcd3,
        sortCd: this.sortCd,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        } else {
          setPaging(this, res);
        }
        this.isLoading = false;
      }).catch(e=>{
        console.error(e);
        this.isLoading = false;
      });
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getPlaygroundList();
    },
    selectSort() {
      this.getPlaygroundList(true);
    },
    changeCtgr(dcd) {
      this.plygrndCtgrDcd3 = dcd;
      this.getPlaygroundList(true);
    },
    searchTagKeyword(){
      this.getPlaygroundList(true);
    }
  }
};
</script>
